const paymentForm = document.querySelector('#pte-subscribe-form');
if (paymentForm) {
  const stripe = Stripe(paymentForm.dataset.talk);

  let elements;

  initialize();

  paymentForm.addEventListener('submit', handleSubmit);

  // Fetches a payment intent and captures the client secret
  async function initialize() {
    const clientSecret = document.getElementById('stripe_token').value;

    const appearance = {
      theme: window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'night'
        : 'stripe',
    };
    elements = stripe.elements({ appearance, clientSecret });

    const paymentElementOptions = {
      layout: 'accordion',
    };

    const paymentElement = elements.create('payment', paymentElementOptions);
    paymentElement.mount('#payment-element');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const locationOrigin =
      window.location.protocol + '//' + window.location.host;
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${locationOrigin}/subscriptions/success`,
      },
    });

    if (error) {
      showMessage(error.message);
    }

    setLoading(false);
  }

  // ------- UI helpers -------

  function showMessage(messageText) {
    const messageContainer = document.querySelector('#payment-message');

    messageContainer.classList.remove('hidden');
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add('hidden');
      messageContainer.textContent = '';
    }, 10000);
  }

  // Show a spinner on payment submission
  function setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector('.stripe-checkout-box button').disabled = true;
      document.querySelector('#spinner').classList.remove('hide-important');
      document.querySelector('#button-text').classList.add('hide');
    } else {
      document.querySelector('.stripe-checkout-box button').disabled = false;
      document.querySelector('#spinner').classList.add('hide-important');
      document.querySelector('#button-text').classList.remove('hide');
    }
  }
}
